

import Axios from 'axios';

import { erpfapi } from 'variables/url';
import { getUser } from 'services/authService';

//Common
export const isBrowser = () => typeof window !== 'undefined';

export const getActiveChart = () =>
  isBrowser() && window.localStorage.getItem('activeChart')
    ? JSON.parse(window.localStorage.getItem('activeChart'))
    : null;

export const setActiveChart = chart => {
  window.localStorage.setItem('activeChart', JSON.stringify(chart));
};

export const getActiveTerms = () =>
  isBrowser() && window.localStorage.getItem('activeTerms')
    ? JSON.parse(window.localStorage.getItem('activeTerms'))
    : {};

export const setActiveTerms = terms => {
  window.localStorage.setItem('activeTerms', JSON.stringify(terms));
};

export const getActiveDate = () =>
  isBrowser() && window.localStorage.getItem('activeDate')
    ? JSON.parse(window.localStorage.getItem('activeDate'))
    : {};

export const setActiveDate = terms => {
  window.localStorage.setItem('activeDate', JSON.stringify(terms));
};

export const getTitleExtension = (chart, extend) => {
  if (chart != null) {
    let updatedTitle = chart.title;

    if (chart.type === 'line') {
      let titleOnly = chart.title.split('by')[0];

      switch (extend) {
      case 'interval':
        switch (chart.xAxis.interval) {
        case '1d':
          updatedTitle = `${chart.title} by Day`;
          break;
        case '1w':
          updatedTitle = `${chart.title} by Week`;
          break;
        case '1M':
          updatedTitle = `${chart.title} by Month`;
          break;
        case '1q':
          updatedTitle = `${chart.title} by Quarter`;
          break;
        case '1y':
          updatedTitle = `${chart.title} by Year`;
          break;
        }

        break;

      case 'division':
        updatedTitle = `${titleOnly} by Division`;
        break;

      case 'product_line':
        updatedTitle = `${titleOnly} by Product Line by Division`;
        break;
      }
    }

    return updatedTitle;
  }
};

//Get Elastic Top Data
export const getElasticTop = async (data, dates) => {
  return await Axios({
    method: 'post',
    url: erpfapi + '/api/sales/topdata',
    data: {
      index: data.index,
      top_field: data.sliceBy.field,
      dateFrom: dates.from,
      dateTo: dates.to,
      aggregate: data.sizeBy.aggregate,
      aggs_field: data.sizeBy.field,
      limit: data.sliceBy.limit,
      date_field: data.date_range.field,
      terms: data.terms,
    },
    headers: {
      'x-access-token': getUser().token,
      'content-type': 'application/json',
    },
  });
};

//Get Elastic Range Data
export const getElasticRange = async (data, dates) => {
  return await Axios({
    method: 'post',
    url: erpfapi + '/api/sales/rangedata',
    data: {
      index: data.index,
      dateFrom: dates.from,
      dateTo: dates.to,
      aggregate: data.yAxis.aggregate,
      aggs_field: data.yAxis.field,
      date_field: data.date_range.field,
      interval: data.xAxis.interval,
      terms: data.terms,
    },
    headers: {
      'x-access-token': getUser().token,
      'content-type': 'application/json',
    },
  });
};

//Get Elastic Range Series Data
export const getElasticRangeSeries = async ({
  date,
  terms,
  aggsField,
  index,
  interval,
  topField,
  limit,
}) => {
  return await Axios({
    method: 'post',
    url: erpfapi + '/api/sales/rangeseriesdata',
    data: {
      index,
      date,
      terms,
      aggsField,
      interval,
      topField,
      limit,
    },
    headers: {
      'x-access-token': getUser().token,
      'content-type': 'application/json',
    },
  });
};

//Get RANGE SERIES DATA Accounts Receivable Balances
export const getAccountReceivableRSD = async ({ date, terms, index, aggsField }, cancelToken) => {
  return await Axios({
    method: 'post',
    url: erpfapi + '/api/sales/rsd-receivable',
    data: {
      date,
      terms,
      index,
      aggsField,
    },
    cancelToken,
    headers: {
      'x-access-token': getUser().token,
      'content-type': 'application/json',
    },
  });
};

//Get accounts Receivable Top Data
export const getAccountReceivableTopData = async ({
  date,
  terms,
  index,
  aggsField,
  topField,
  limit,
}) => {
  return await Axios({
    method: 'post',
    url: erpfapi + '/api/sales/td-receivable',
    data: {
      date,
      terms,
      index,
      aggsField,
      topField,
      limit,
    },
    headers: {
      'x-access-token': getUser().token,
      'content-type': 'application/json',
    },
  });
};

//Get Elastic Sales Data
export const getElasticIndexData = async (
  { index, fields, page, limit, order, sortBy, terms, filters, date, sum },
  cancelToken
) => {
  order = order.toString().toLowerCase();

  return await Axios({
    method: 'post',
    url: erpfapi + '/api/sales/data',
    data: {
      index,
      fields,
      page,
      limit,
      order,
      sortBy,
      terms,
      filters,
      date,
      sum,
    },
    cancelToken,
    headers: {
      'x-access-token': getUser().token,
      'content-type': 'application/json',
    },
  });
};

//Export Sales Data
export const exportSalesData = async ({ index, fields, terms, filters, date }, cancelToken) => {
  return await Axios({
    method: 'post',
    url: erpfapi + '/api/sales/export',
    data: {
      index,
      fields,
      terms,
      filters,
      date,
    },
    cancelToken,
    headers: {
      'x-access-token': getUser().token,
      'content-type': 'application/json',
    },
  });
};

//Get Elastic Sales Data
export const getAvailableTerms = async (
  { index, field, search, terms, filters, date, filterFor, page },
  cancelToken
) => {
  if (!index) {
    if (filterFor === 'dashboard') {
      index = ['invoices', 'orders', 'accountsreceivables'];
    } else {
      index = ['invoices', 'orders', 'accountsreceivables', 'subsidiary', 'subsidiaryLocation'];
    }
  }

  return await Axios({
    method: 'post',
    url: erpfapi + '/api/sales/available-terms',
    data: {
      index,
      field,
      search,
      terms,
      filters,
      page,
      date,
    },
    cancelToken,
    headers: {
      'x-access-token': getUser().token,
      'content-type': 'application/json',
    },
  });
};

export const newChart = data => {
  const result = {
    chart: data,
    layout: {
      x: 0,
      y: Infinity,
      w: 5,
      h: 5,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 12,
      i: data._id,
    },
  };

  return result;
};

//Line Charts Y Axis
export const customAxisLeft = (aggregate, isNotUsd) => {
  let axisLeft = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
  };

  if (aggregate === 'value_count') {
    axisLeft.format = value =>
      `${value < 0 ? '(' : ''}${Math.abs(parseInt(value)).toFixed(0)}${value < 0 ? ')' : ''}`;
  } else {
    axisLeft.format = value =>
      `${Number(value) < 0 ? '(' : ''}${isNotUsd ? '' : '$'}${Math.abs(Number(value)).toLocaleString('en-US')}${
        Number(value) < 0 ? ')' : ''
      }`;
  }

  return axisLeft;
};

// The samea s customAxisLeft but you can have your own currencySymbol
export const customAxisLeft2 = (aggregate, isNotUsd, currencySymbol) => {
  let axisLeft = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    format: value => value // Default format to avoid undefined errors
  };

  if (aggregate === 'value_count') {
    axisLeft.format = value =>
      `${value < 0 ? '(' : ''}${Math.abs(parseInt(value)).toFixed(0)}${value < 0 ? ')' : ''}`;
  } else {
    axisLeft.format = value =>
      `${Number(value) < 0 ? '(' : ''}${isNotUsd ? '' : currencySymbol}${Math.abs(Number(value)).toLocaleString('en-US')}${Number(value) < 0 ? ')' : ''}`;
  }

  return axisLeft;
};


export const customAxisBottom = interval => {
  let axisBottom = {
    tickRotation: -45,
  };

  switch (interval) {
  case '1d':
    axisBottom.tickValues = 'every day';
    axisBottom.format = '%m-%d-%Y';

    break;
  case '1w':
    axisBottom.tickValues = 'every monday';
    axisBottom.format = '%m-%d-%Y';

    break;
  case '1M':
    axisBottom.tickValues = 'every month';
    axisBottom.format = '%b-%y';

    break;
  case '1q':
    axisBottom.tickValues = 'every 3 months';
    axisBottom.format = '%b-%y';

    break;
  case '1y':
    axisBottom.tickValues = 'every year';
    axisBottom.format = '%Y';

    break;
  default:
    axisBottom.tickValues = 'every month';
    axisBottom.format = '%b-%y';
    break;
  }

  return axisBottom;
};

const checkMaxValue = (max, maxValue) => {
  let percentageMaxValue = maxValue;

  if (max < percentageMaxValue * 0.15) {
    percentageMaxValue = percentageMaxValue * 0.15;
  } else if (max < percentageMaxValue * 0.25) {
    percentageMaxValue = percentageMaxValue * 0.25;
  } else if (max < percentageMaxValue * 0.5) {
    percentageMaxValue = percentageMaxValue * 0.5;
  } else if (max < percentageMaxValue * 0.75) {
    percentageMaxValue = percentageMaxValue * 0.75;
  }

  return percentageMaxValue;
};

const checkMinValue = (min, minValue) => {
  let percentageMinValue = minValue;

  if (min > percentageMinValue * 0.15) {
    percentageMinValue = percentageMinValue * 0.15;
  } else if (min > percentageMinValue * 0.25) {
    percentageMinValue = percentageMinValue * 0.25;
  } else if (min > percentageMinValue * 0.5) {
    percentageMinValue = percentageMinValue * 0.5;
  } else if (min > percentageMinValue * 0.75) {
    percentageMinValue = percentageMinValue * 0.75;
  }

  return percentageMinValue;
};

export const customYScale = array => {
  let yScale = {
    type: 'linear',
    stacked: false,
    reverse: false,
    min: 0,
    max: 0,
  };

  if (array !== null) {
    let max = Math.round(
      Math.max.apply(
        Math,
        array.map(i => {
          return i.max;
        })
      )
    );

    let maxValue = Math.pow(10, Math.ceil(Math.log10(max)));
    yScale.max = checkMaxValue(max, isNaN(maxValue) ? max : maxValue);

    let min = Math.round(
      Math.min.apply(
        Math,
        array.map(i => {
          return i.min;
        })
      )
    );

    let minValue = Math.pow(10, Math.ceil(Math.log10(min)));
    if(isFinite(minValue)) {
      minValue = min;
    }

    yScale.min = !isNaN(checkMinValue(min, isNaN(minValue) ? min : minValue)) ? checkMinValue(min, isNaN(minValue) ? min : minValue) : 'auto';
  }

  return yScale;
};


export const customYScale2 = array => { // 08/12/24 MAN This here is for the 'customLine' widgets we make.
  let yScale = {
    type: 'linear',
    stacked: false,
    reverse: false,
    min: 0,
    max: 0,
  };

  if (array !== null && array.length > 0) {
    // Get the largest value from the dataset
    const largestValue = Math.max(...array.flatMap(i => i.data.map(d => d.y)));

    // Set the max value to slightly higher than the largest value
    yScale.max = Math.ceil(largestValue / 1000) * 800;

    // Optional: Set a reasonable min value
    yScale.min = 0; // Adjust if needed
  }

  return yScale;
};

export const getElasticMonthlyWidget = async data => {
  const { userId, token } = getUser();
  let body = {
    subsidiaryLocation: data.terms && data.terms.subsidiaryLocation,
    subsidiary: data.terms && data.terms.subsidiary,
    filters: data.filters,
  };

  return await Axios({
    method: 'post',
    url: erpfapi + '/api/monthly/widgets/' + userId + '/' + data.index + '/v2',
    headers: {
      'x-access-token': token,
      'content-type': 'application/json',
    },
    data: body,
  });
};

//Get Elastic Sales Data
export const getElasticMonthlyWidgetDrilldown = async data => {
  const { userId, token } = getUser();
  let body = {
    subsidiaryLocation: data.terms && data.terms.subsidiaryLocation,
    subsidiary: data.terms && data.terms.subsidiary,
    filters: data.filters,
    terms: data.terms,
    widgetType: data.widgetType ? data.widgetType : '',
    page: data.page,
    limit: data.limit,
    order: data.order,
    sortBy: data.sortBy,
    theFields: data.theFields,
    widgetkeysName: data.widgetkeysName,
    widgetDatas: data.widgetDatas,
  };

  return await Axios({
    method: 'post',
    url: erpfapi + '/api/monthly/widgets/' + userId + '/' + data.index + '/drilldown/v2',
    headers: {
      'x-access-token': token,
      'content-type': 'application/json',
    },
    data: body,
  });
};
